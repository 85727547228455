import cookies from 'js-cookie';

interface IPermissionGate {
  permissions: string[];
  children: React.ReactNode;
}

export const PermissionGate = ({ permissions, children }: IPermissionGate) => {
  const userPermissions = JSON.parse(cookies.get('rules'));
  if (permissions.some(permission => userPermissions.includes(permission))) {
    return children;
  }

  return null;
};
