/* eslint-disable no-return-await */
import { ESTOQUE_BACKEND_URL } from '../settings';
import { api } from './api';

class ProductBaseService {
  url: string;
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async list({ pageSize, page, productBaseId, orderBy, search }: any) {
    const url = `${this.url}/api/products/v1/product-base`;
    return api.get(url, {
      params: { pageSize, page, productBaseId, orderBy, search }
    });
  }

  async postProductBase(form: any) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/`;
    return api.post(url, form);
  }

  async updateProductBase(data: { id: any; }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${data.id}`;
    return api.put(url, data);
  }

  async postProductSample(form: any) {
    const url = `${this.url}/api/products/v1/product-sample`;
    return api.post(url, form);
  }

  async getProductBase(params: any) {
    const url = `${this.url}/api/products/v1/product-base`;
    return api.get(url, { params })
  }

  async getProductBaseId(id: any) {
    const url = `${this.url}/api/products/v1/product-base`;
    return api.get(url, {
      params: {
        productBaseId: id
      }
    });
  }

  async updateImageProductBase({ image, id }: any) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${id}/image`;
    return api.put(url, { file: image });
  }

  async getProductBaseById(id: any) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${id}`;
    return api.get(url);
  }
}

const productBaseService = new ProductBaseService();
export default productBaseService;
