import { useRef } from 'react';

export const useDebaunce = ({ fn, delay }: any) => {
  const timeoutRef = useRef(null);

  function debaunceFn(...params: any[]) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...params);
    }, delay);
  }

  return debaunceFn;
};
