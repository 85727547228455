import { useTransition } from '@react-spring/web';

import { Popup } from './Popup';
import { Container } from './styles';

export interface IPopupMessage {
  id: string;
  type: 'success' | 'error' | 'info';
  title: string;
  description?: React.ReactNode;
  autoClose?: boolean;
}

type IPopupContainerProps = {
  messages: IPopupMessage[];
};

export const PopupContainer = ({ messages }: IPopupContainerProps) => {
  const transition = useTransition(messages, {
    keys: message => message.id,
    from: { right: '-120%', opacity: 0 },
    enter: () => [{ right: '2%', opacity: 1 }, { right: '-2%' }, { right: '2%' }, { right: '0%' }],
    leave: () => [{ right: '3%' }, { right: '-120%', opacity: 0 }],
    trail: 100,
    config: { duration: 100 }
  });

  return (
    <Container>
      {transition((style, popup) => (
        <Popup style={style} message={popup} />
      ))}
    </Container>
  );
};
