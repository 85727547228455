import { TableCell as TableCellMui } from '@material-ui/core';
import { memo } from 'react';

import { useStyles } from './styles';

const TableCellBodyComponent = props => {
  const classes = useStyles();

  return (
    <TableCellMui {...props} className={classes.root}>
      {props.children}
    </TableCellMui>
  );
};

const TableCellBody = memo(TableCellBodyComponent);

export default TableCellBody;
