import styled from 'styled-components';

export const ContainerPage = styled('main')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  backgroundColor: '#FCFCFC'
});

export const ContainerImage = styled('section')({
  width: '50%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#B9CA94'
});

export const Image = styled('img')({
  width: '50%',
  maxWidth: '600px'
});
