import { TableRow as TableRowMui } from '@material-ui/core';
import { memo } from 'react';

const TableRowComponent = props => {
  const { children } = props;

  return <TableRowMui {...props}>{children}</TableRowMui>;
};

const TableRow = memo(TableRowComponent);

export default TableRow;
