import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& .MuiPaper-root, & .MuiDialog-container': {
      maxHeight: '80vh',
      maxWidth: '46rem',
      width: '100%',
      margin: '0 0.5rem',
      borderRadius: '1.25rem'
    }
  },
  container: {
    background: '#FFFFFF',
    width: '100%',
    padding: ' 0 2rem 2rem',
    borderRadius: '6px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px'
    }
  },
  containerTitle: {
    position: 'sticky',
    top: '0',
    left: '0',
    width: '100%',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha('#FFFFFF', 0.72),
    zIndex: 2,
    padding: '1.5rem 0',
    paddingRight: '1rem',
    marginBottom: '0.8rem'
  },
  closeBtn: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
    height: '2.5rem',
    width: '2.5rem',
    '& svg': {
      fontSize: '1.6rem'
    }
  },
  title: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '1.25rem',
    lineHeight: '2.37rem',
    fontWeight: 600,
    color: '#353535'
  }
});
