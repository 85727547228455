import { Typography, InputLabel, TextField, Button } from '@mui/material';
import styled from 'styled-components';

export const ContainerForm = styled('section')({
  padding: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80%',
  position: 'relative',
  flexDirection: 'column',
  maxWidth: '400px',
  margin: '0 auto'
});

export const ContainerLogo = styled('div')({
  display: 'flex',
  color: '#89BD23',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '.5rem',
  position: 'absolute',
  top: '40px'
});

export const TextWelcome = styled(Typography)({
  fontSize: '36px !important',
  fontWeight: '500 !important',
  color: '#353535',
  letterSpacing: '0.04em !important',
  marginBottom: '2rem !important'
});

export const Label = styled(InputLabel)({
  fontWeight: '500 !important',
  marginBottom: '8px !important',
  marginTop: '13px'
});

export const Input = styled(TextField)({
  'borderRadius': '12px',
  '& *': {
    border: 'none !important'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#F0F0F0'
  }
});

export const ButtonSubmit = styled(Button)({
  '&[disabled]': {
    color: '#FFF !important',
    backgroundColor: '#C4C4C4 !important'
  },
  'marginTop': '1.5rem !important',
  'height': '45px !important',
  'borderRadius': '12px !important',
  'textTransform': 'none !important' as 'none',
  'background': '#6E8247 !important'
});
