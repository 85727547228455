import { AppBar, Box, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ContainerPage } from 'components/ContainerPage';
import { OptionsMenu } from 'components/OptionsMenu';
import { useTableParams } from 'hooks/useTableParams';
import React, { useState } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AiFillPrinter } from 'react-icons/ai';
import productBaseService from 'services/productBaseService';
import Table from 'shared/Table';
import { mapStatesString } from 'utils/mapStates';
import { typeMapping } from 'utils/typeMapping'

import { ModalPrint } from './components/ModalPrint/Index'
import { useStyles } from './styles';
import { useAuth } from 'hooks/useAuth';

export default function ProductBase() {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: (params: any) => productBaseService.getProductBase(params)
    });
  const [dialogPrint, setDialogPrint] = useState({
    isOpen: false,
    data: {}
  });

  const classes = useStyles();
  const { onSignOut } = useAuth();

  const options = [
    {
      title: 'Imprimir etiqueta',
      icon: AiFillPrinter,
      permissions: [
        'CD',
        'Tech',
        'Stock View',
        'Previsão',
        'Estoque',
        'Estoque físico',
        'Logistic'
      ],
      action: (data: any) =>
        setDialogPrint({
          isOpen: true,
          data
        })
    }
  ];

  const columns = [
    {
      field: 'image',
      label: 'Imagem',
      align: 'center',
      style: { minWidth: '50px' },
      rendercell: ({ data }: any) => (
        <img
          style={{ width: 50, height: 50 }}
          src={data.image}
          title={data.name}
        />
      )
    },
    {
      field: 'id',
      label: 'Id',
      align: 'center',
      style: { minWidth: '70px' }
    },
    {
      field: 'name',
      label: 'Nome',
      style: { minWidth: '250px' }
    },
    {
      field: 'status',
      label: 'Status',
      align: 'center',
      style: { minWidth: '130px' },
      formatter: (value: any) => mapStatesString(value)
    },
    {
      field: 'shelfLife',
      label: 'Validade',
      align: 'center',
      style: { minWidth: '130px' }
    },
    {
      field: 'unitWeight',
      label: 'Peso Unitário',
      align: 'center',
      style: { minWidth: '150px' },
      formatter: (value: { toFixed: (arg0: number) => number | bigint; }) => {
        return new Intl.NumberFormat('pt-BR').format(value?.toFixed(2));
      }
    },
    {
      field: 'purchaseType',
      label: 'Tipo de entrada',
      align: 'center',
      style: { minWidth: '160px' },
      formatter: (value: any) => typeMapping(value)
    },
    {
      field: '',
      label: '',
      align: 'center',
      rendercell: ({ data }: any) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.titleNav}
            >
              Nabo
            </Typography>
            <IconButton color="inherit" onClick={onSignOut}>
              <ExitToAppIcon style={{ color: '#fff', fontSize: 25 }} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Table
          title="Produtos"
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
        />
      </ContainerPage>

      <ModalPrint
        close={setDialogPrint}
        isOpen={dialogPrint.isOpen}
        dataProduct={dialogPrint.data}
      />
    </>
  );
}
