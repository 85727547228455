/* eslint-disable no-undef */
import {
  Button,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { useEffect, useState, memo } from 'react';
import { MdFilterList, MdOutlineArrowDropUp } from 'react-icons/md';

import {
  ArrowIconOption,
  ButtonOpenFilter,
  ContainerButtonSubmit,
  ContainerListFilters,
  ContainerTextOption,
  Filter,
  FilterContainer,
  FilterOption,
  ListFilters
} from './styles';

const FilterMenu = ({ filterProps, onChangeParams }) => {
  const [openLists, setOpenLists] = useState({
    main: false,
    secundary: null
  });

  const [checkeds, setCheckeds] = useState({});

  const handleCheckBox = (filter, value) => {
    setCheckeds(state => {
      if (state[filter.parameter]?.includes(value.id)) {
        const newState = {
          ...state,
          [filter.parameter]: state[filter.parameter].filter(
            item => item !== value.id
          )
        };
        return newState;
      }

      const newState = {
        ...state,
        [filter.parameter]: [...(state[filter.parameter] || []), value.id]
      };
      return newState;
    });
  };

  const handleFilter = async () => {
    setOpenLists({ main: false, secundary: null });
    onChangeParams(checkeds);
  };

  const handleClickOptionFilter = (e, option) => {
    const path =
      e.nativeEvent?.composedPath() ||
      e.nativeEvent?.path ||
      e.nativeEvent?.target;
    if (!path.some(item => item.id === `collapse-${option.parameter}`)) {
      if (openLists.secundary === option.parameter) {
        setOpenLists(state => ({ ...state, secundary: null }));
      } else {
        setOpenLists(state => ({
          ...state,
          secundary: option.parameter
        }));
      }
    }
  };

  const handleReset = async () => {
    setCheckeds(state => {
      const newState = { ...state };
      Object.keys(newState).forEach(key => {
        newState[key] = [];
      });
      onChangeParams(newState);
      return newState;
    });
  };

  useEffect(() => {
    document.addEventListener('click', e => {
      const path = e.composedPath() || e.path || e.target;
      const close = path?.some(item => item?.id === 'filter');
      if (!close) {
        setOpenLists({ main: false, secundary: null });
      }
    });
  }, [setOpenLists]);

  return (
    <FilterContainer component="ul" id="filter">
      <ButtonOpenFilter
        button
        onClick={() => {
          setOpenLists(state => ({
            main: !state.main,
            secundary: state.main ? null : state.secundary
          }));
        }}
        isOpen={openLists.main}
      >
        <ListItemIcon>
          <MdFilterList size={25} />
        </ListItemIcon>
        <ListItemText primary="Filtrar" />
        <MdOutlineArrowDropUp
          size={25}
          className="svg-icon-arrow svg-icon-color"
        />
      </ButtonOpenFilter>
      <Collapse
        in={openLists.main}
        timeout={{ appear: 0, enter: 800, exit: 800 }}
      >
        <List
          component="ul"
          disablePadding
          timeout={{ appear: 1, exit: 1, enter: 1 }}
        >
          {filterProps.map(filterOptions => (
            <FilterOption
              key={filterOptions.label}
              onClick={e => handleClickOptionFilter(e, filterOptions)}
              id={filterOptions.parameter}
            >
              <ContainerTextOption>
                <ListItemText
                  primary={filterOptions.label}
                  style={{ paddingLeft: '0.5rem' }}
                />

                <ArrowIconOption
                  size={25}
                  isOpen={openLists.secundary === filterOptions.parameter}
                />
              </ContainerTextOption>

              <ContainerListFilters
                in={openLists.secundary === filterOptions.parameter}
                id={`collapse-${filterOptions.parameter}`}
                timeout={{ appear: 0, enter: 800, exit: 0 }}
                unmountOnExit
              >
                <ListFilters
                  component="ul"
                  style={{ paddingInlineStart: '10px' }}
                >
                  {filterOptions.values
                    .sort((a, b) => {
                      return a.label > b.label ? 1 : -1;
                    })
                    .map(filter => (
                      <Filter
                        button
                        key={filter.id}
                        onClick={() => {
                          handleCheckBox(filterOptions, filter);
                        }}
                      >
                        <ListItemText
                          primary={
                            <span>
                              <Checkbox
                                checked={checkeds[
                                  filterOptions.parameter
                                ]?.includes(filter.id)}
                              />
                              {filter.label}
                            </span>
                          }
                        />
                      </Filter>
                    ))}
                </ListFilters>
              </ContainerListFilters>
            </FilterOption>
          ))}
          <ListItem style={{ marginTop: '1rem' }}>
            <ContainerButtonSubmit>
              <Button
                onClick={() => {
                  handleFilter();
                }}
              >
                Filtrar
              </Button>
              <Button
                onClick={() => {
                  setOpenLists({ main: false, secundary: null });
                  handleReset();
                }}
              >
                Limpar Filtros
              </Button>
            </ContainerButtonSubmit>
          </ListItem>
        </List>
      </Collapse>
    </FilterContainer>
  );
};

export default memo(FilterMenu);
