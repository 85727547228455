import { ESTOQUE_BACKEND_URL } from 'settings';
import { ApiService } from './api';

interface IResponseToken {
  access: string;
  refresh: string;
}

export interface IDataToken {
  username: string;
  password: string;
}

interface IPermissions {
  user_groups_names: string[];
  user_login: string;
  user_id: number;
}

class AuthService {
  constructor(private url: string, private readonly api: ApiService) {}

  public getToken = async (data: IDataToken) => {
    return this.api.post<IResponseToken, IDataToken>(`${this.url}/api/blueberry/token`, data);
  };

  public updateToken = async (token: string) => {
    return this.api.updateAuthToken(token);
  };

  public getPermissions = async (headers?: any) => {
    return this.api.get<IPermissions>(`${this.url}/api/blueberry/me/`, headers);
  };
}

export const authService = new AuthService(ESTOQUE_BACKEND_URL, new ApiService());
