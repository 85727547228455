function whatState() {
  if (
    window.location.href.includes('localhost') ||
    window.location.href.includes('stg')
  ) {
    return {
      ESTOQUE_BACKEND_URL: 'https://stg.estoque.frexco.com.br',
      ABACATE_BACKEND_URL: 'https://stg.abacate.frexco.com.br',
      PRODUCT_API_BACKEND_URL: 'https://stg.ms.frexco.com.br'
    };
  }
  if (window.location.href.includes('cd.estoque')) {
    return {
      ESTOQUE_BACKEND_URL: 'https://cd.estoque.frexco.com.br',
      ABACATE_BACKEND_URL: 'https://abacate.frexco.com.br',
      PRODUCT_API_BACKEND_URL: 'https://ms.frexco.com.br'
    };
  }
  return {
    ESTOQUE_BACKEND_URL: 'https://estoque.frexco.com.br',
    ABACATE_BACKEND_URL: 'https://abacate.frexco.com.br',
    PRODUCT_API_BACKEND_URL: 'https://ms.frexco.com.br'
  };
}

const { ESTOQUE_BACKEND_URL, ABACATE_BACKEND_URL, PRODUCT_API_BACKEND_URL } =
  whatState();

export { ESTOQUE_BACKEND_URL, ABACATE_BACKEND_URL, PRODUCT_API_BACKEND_URL };
