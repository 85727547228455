import { ESTOQUE_BACKEND_URL } from '../settings';
import { api } from './api';

class PrintService {
  url: string;
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async printTags(data: { sku: any; amountOfTags: any; prePicking: boolean; }) {
    const url = `${this.url}/api/nabo/v1/nabo/pre-picking/print-tag`;
    return api.post(url, data);
  }
}

const printService = new PrintService();
export default printService;
