import { useState } from 'react';
import { Typography, InputAdornment, IconButton } from '@mui/material';
import * as yup from 'yup';
import { ButtonSubmit, ContainerForm, ContainerLogo, Input, Label, TextWelcome } from './styles';
import LogoIMG from 'assets/images/logo-frexco.svg';
import { useFormik } from 'formik';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useAuth } from 'hooks/useAuth';

const validationSchema = yup.object({
  username: yup.string().required('Nome de usuário é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
});

const Form = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { onSignIn } = useAuth();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema,
    onSubmit: values => {
      onSignIn(values);
    }
  });

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValid = !(Boolean(formik.values.password) && Boolean(formik.values.username));

  return (
    <ContainerForm>
      <ContainerLogo>
        <img src={LogoIMG} alt='logo da frexco' />
        <Typography>Nabo</Typography>
      </ContainerLogo>
      <TextWelcome variant='h1'>Olá, bem-vindo ao Nabo</TextWelcome>
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
        <Label htmlFor='username'>Usuário</Label>
        <Input
          fullWidth
          id='username'
          name='username'
          placeholder='nome.sobrenome'
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <Label htmlFor='password'>Senha</Label>
        <Input
          fullWidth
          id='password'
          name='password'
          placeholder='senha'
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <ButtonSubmit fullWidth variant='contained' type='submit' disabled={isValid}>
          Entrar
        </ButtonSubmit>
      </form>
    </ContainerForm>
  );
};

export default Form;
