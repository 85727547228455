/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Button } from 'components/Button/index'
import { useAuth } from 'hooks/useAuth';
import { usePopup } from 'hooks/usePopup';
import { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useForm, useWatch } from 'react-hook-form';
import { FiImage } from 'react-icons/fi';
import batchServiceV3 from 'services/batchServiceV3';
import naboProductConfigService from 'services/naboProductConfigService';
import skuService from 'services/skuService';
import { generateBatchExternalCode } from 'utils/generateBatchExternalCode';
import * as Yup from 'yup';

import { checkBatch } from '../utils/checkBatch';
import { printTag } from '../utils/printTag';
import { useStyles } from './styles';

const schema = Yup.object().shape({
  amountOfTags: Yup.number()
    .typeError('Número de páginas deve ser um número')
    .required('Número de páginas é obrigatório')
    .positive('Número de páginas deve ser positivo')
    .integer('Número de páginas deve ser um número inteiro'),
  sku: Yup.string()
    .typeError('Porção deve ser um número')
    .required('Porção é obrigatório'),
  prePicking: Yup.number()
    .typeError('Pre picking deve ser um número')
    .required('Pre picking é obrigatório'),
  printer: Yup.string().required('Impressora é obrigatório'),
  batchRequired: Yup.boolean(),
  batchId: Yup.string().when('batchRequired', {
    is: true,
    then: Yup.string().required('ID do Lote precisa ser informado.')
  })
});

const allPrinters = [
  {
    id: 'impressora1.E1',
    name: 'Padrão - Pré-picking',
    delay: 5
  },
  {
    id: 'impressora1.E3',
    name: 'Recebimento',
    delay: 10
  },
  {
    id: 'impressora1.E4',
    name: 'Folhosas',
    delay: 20
  },
  {
    id: 'impressora1.E5',
    name: 'Estoque Seco',
    delay: 20
  }
];

export const Form = ({ dataProduct, handleClose, onBack }) => {
  const { user } = useAuth();
  const { addPopup } = usePopup();
  const timeInterval = useRef(null);
  const { id, batchIdFromScan, image, category, name } = dataProduct;
  const { register, control, formState, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const skuValue = useWatch({
    name: 'sku',
    control
  });

  const [listOfWeight, setListOfWeight] = useState([]);
  const [loadingSkus, setLoadingSkus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const [batchClassification, setBatchClassification] = useState('');
  const [printers, setPrinters] = useState(() => {
    return allPrinters.map((printer, index) => {
      return {
        ...printer,
        selected: index === 0,
        count: 0
      };
    });
  });
  const { errors } = formState;
  const printerSelected = printers.find(item => item.selected);
  const classes = useStyles();
  const classificationsBatch = ['A', 'B', 'C'];

  const setBatchId = batchId => {
    setValue('batchId', batchId);
  };

  const onSelectPrinter = printerName => {
    setValue('printer', printerName);
    setPrinters(state =>
      state.map(item => ({ ...item, selected: item.name === printerName }))
    );
  };

  const getProductInfo = async () => {
    if (!id) return;
    setLoadingSkus(true);
    try {
      const data = await skuService.getSku({
        productBaseId: id,
        status: 'active'
      });
      setListOfWeight(data?.results);
      const skuData = data?.results[0]?.sku ?? 'empty';
      setValue('sku', skuData);
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: 'error',
        title: 'Erro ao pegar SKUs',
        description: errorData?.message || errorData?.msg
      });
    }
    setLoadingSkus(false);
  };

  const getProductConfig = async () => {
    try {
      const data = await naboProductConfigService.getSku({
        sku: skuValue
      });
      const naboConfig = data?.[0];
      if (!naboConfig) {
        addPopup({
          type: 'error',
          title: 'Erro ao pegar configuração do SKU.',
          description: `Não foi possível obter configuração para o SKU "${skuValue}".`
        });
      }
      setValue('batchRequired', !['gustavo.scopel'].includes(user));
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: 'error',
        title: 'Erro ao obter configuração do SKU.',
        description: errorData?.message || errorData?.msg
      });
    }
  };

  const mapPrinters = printerName => {
    const printer = allPrinters.find(item => item.name === printerName);
    return printer.id;
  };

  const handlePrint = async data => {
    try {
      setLoading(true);
      let batchExternalCode = '';
      const sku = {
        ...listOfWeight.find(item => item.sku === data.sku),
        product_base: dataProduct
      };
      const productBaseId = sku.product_base?.id;
      const batchId = data.batchId;
      const classification = data.classification;

      if (printerSelected.count > 0) {
        throw new Error('Aguarde o tempo de espera para imprimir novamente');
      }

      if (batchId && !view) {
        const batchValidate = await checkBatch({
          batchId,
          productBaseId
        });
        batchExternalCode = batchValidate.batchExternalCode;

        if (batchValidate.isProductExpired) {
          setBatchClassification(batchValidate.classification);
          setView(1);
          return;
        }
      }

      if (classification) {
        const { data: batchData } = await batchServiceV3.patchBatch(batchId, {
          classification
        });
        batchExternalCode = generateBatchExternalCode(batchData);
      }

      await printTag({
        batchExternalCode,
        data,
        printer: mapPrinters(printerSelected.name),
        sku
      });

      addPopup({
        type: 'success',
        title: 'Sucesso ao imprimir'
      });
      setView(0);
      setBatchClassification('');
      setValue('classification', '');
      startCount(printerSelected.name);
    } catch (error) {
      addPopup({
        type: 'error',
        title: 'Erro ao imprimir',
        description: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const startCount = printerName => {
    setPrinters(
      printers.map(printer => {
        if (printer.name === printerName) {
          return {
            ...printer,
            count: printer.delay
          };
        }
        return printer;
      })
    );

    timeInterval.current && clearInterval(timeInterval.current);
    timeInterval.current = setInterval(() => {
      setPrinters(state => {
        return state.map(item => {
          if (item.count > 0) {
            return {
              ...item,
              count: item.count - 1
            };
          }
          return item;
        });
      });
    }, 1000);
  };

  useEffect(() => {
    getProductInfo();
    if (batchIdFromScan) {
      setBatchId(batchIdFromScan);
    }
  }, [id]);

  useEffect(() => {
    getProductConfig();
  }, [skuValue]);

  const children = {
    0: (
      <Grid container spacing={3} className={classes.responsive}>
        <Grid item sm={6} xs={12}>
          <InputLabel>Porcionamento (SKU)</InputLabel>
          {loadingSkus && (
            <ContentLoader
              speed={1}
              width={316}
              height={56}
              className={classes.contentLoader}
            >
              <rect x="0" y="0" rx="4" ry="4" width="316" height="56" />{' '}
            </ContentLoader>
          )}
          {!loadingSkus && (
            <Select
              {...register('sku')}
              error={!!errors?.sku}
              defaultValue={listOfWeight[0]?.sku ?? 'empty'}
              helperText={errors?.sku?.message}
              className={classes.input}
              variant="outlined"
            >
              {listOfWeight.length > 0 ? (
                listOfWeight.map(item => (
                  <MenuItem key={item.id} value={item.sku}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="empty">Não há item ativo</MenuItem>
              )}
            </Select>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          <InputLabel>Quantidade de etiquetas</InputLabel>
          <TextField
            error={!!errors?.amountOfTags}
            helperText={errors?.amountOfTags?.message}
            {...register('amountOfTags')}
            defaultValue={1}
            className={classes.input}
            variant="outlined"
            type="number"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <InputLabel>ID do Lote</InputLabel>
          <TextField
            type="number"
            error={!!errors?.batchId}
            helperText={errors?.batchId?.message}
            defaultValue={''}
            {...register('batchId')}
            className={classes.input}
            variant="outlined"
            onChange={e => setBatchId(e.target.value)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <InputLabel>Caixa/Pré-Picking</InputLabel>
          <Select
            {...register('prePicking')}
            defaultValue={1}
            error={!!errors?.prePicking}
            helperText={errors?.prePicking?.message}
            className={classes.input}
            variant="outlined"
          >
            <MenuItem value={1}>Pré-Picking</MenuItem>
            <MenuItem value={2}>Caixa Pronta</MenuItem>
            <MenuItem value={0}>Caixa</MenuItem>
          </Select>
        </Grid>

        <Grid item sm={6} xs={12}>
          <InputLabel>Impressora</InputLabel>
          <Select
            defaultValue={printers[0].name}
            className={classes.input}
            variant="outlined"
            error={!!errors?.printer}
            helperText={errors?.printer?.message}
            {...register('printer')}
            onChange={e => onSelectPrinter(e.target.value)}
          >
            {printers.map(printer => (
              <MenuItem key={printer.name} value={printer.name}>
                {printer.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    ),
    1: (
      <Box>
        <Typography variant="body1" color="error">
          O Lote está vencido, deseja continuar mesmo assim?
        </Typography>
        <Typography variant="body1">Deseja reclassificar o lote?</Typography>
        <InputLabel>Classificação</InputLabel>
        <Select
          defaultValue={''}
          className={classes.input}
          variant="outlined"
          {...register('classification')}
        >
          <MenuItem value={''}></MenuItem>
          {classificationsBatch
            .filter(classification => classification !== batchClassification)
            .map(classification => (
              <MenuItem value={classification} key={classification}>
                {classification}
              </MenuItem>
            ))}
        </Select>
      </Box>
    )
  }[view];

  return (
    <>
      <Box className={classes.boxAlign}>
        {image ? (
          <Avatar alt={name} src={image} className={classes.image} />
        ) : (
          <FiImage size="5rem" />
        )}
        <Box className={classes.boxAlignText}>
          <Typography className={classes.title}>
            Nome: <strong>{name}</strong>
          </Typography>
          <Typography className={classes.title}>
            ID: <strong>{id}</strong>
          </Typography>
          <Typography className={classes.subTitle}>
            Categoria: <strong>{category}</strong>
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop="2rem"
        component="form"
        onSubmit={params => {
          handleSubmit(handlePrint)(params);
        }}
        textAlign="center"
      >
        {children}
        <Box className={classes.buttons}>
          {!view ? (
            <>
              <Button
                variant="contained"
                className={classes.printButtom}
                onClick={onBack}
              >
                Ler QRCode
              </Button>
              <Button
                key="sendForm"
                variant="contained"
                className={classes.printButtom}
                type="submit"
                disabled={!!printerSelected.count || loading}
              >
                Imprimir {printerSelected.count || ''}
              </Button>
            </>
          ) : (
            <>
              <Button
                key="closeForm"
                variant="contained"
                className={classes.printButtom}
                color="warning"
                onClick={handleClose}
              >
                Cancelar
              </Button>

              <Button
                key="sendForm"
                variant="contained"
                className={classes.printButtom}
                type="submit"
                disabled={!!printerSelected.count || loading}
              >
                Imprimir {printerSelected.count || ''}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
