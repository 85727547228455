import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  boxAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
  },
  image: {
    width: '6rem',
    height: '6rem',
    [theme.breakpoints.between(0, 600)]: {
      maxWidth: '5rem',
      maxHeigth: '5rem'
    }
  },
  boxAlignText: {
    marginLeft: '1.5rem'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '1rem',
    color: '#353535',
    marginBottom: '1rem',
    '& strong': {
      color: '#000000'
    }
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#757575',
    '& strong': {
      color: '#000000'
    }
  },
  input: {
    width: '100%',
    marginTop: '.5rem',
    [theme.breakpoints.between(0, 600)]: {
      minWidth: '80%'
    },
    '& input[type="number"]': {
      textAlign: 'center'
    }
  },
  printButtom: {
    padding: '0.5rem 2rem',
    backgroundColor: 'rgb(110, 130, 71)',
    color: 'white',
    [theme.breakpoints.between(0, 600)]: {
      minWidth: '80%'
    }
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  linearProgress: {
    width: '100%',
    position: 'absolute',
    top: '75px',
    left: '0',
    height: '5px',
    zIndex: 3,
    opacity: props => (props.loading ? 1 : 0)
  },
  contentLoader: {
    border: '1px solid #C4C4C4',
    marginTop: '.5rem',
    borderRadius: '4px'
  },
  responsive: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));
