import { useEffect } from 'react';
import { IDataToken, authService } from 'services/authService';
import { usePopup } from './usePopup';
import cookies from 'js-cookie';
import { api } from 'services/api';
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  const { addPopup } = usePopup();
  const navegate = useNavigate();

  const onSignIn = async (params: IDataToken) => {
    try {
      const reponse = await authService.getToken(params);
      const date = new Date();
      date.setHours(date.getHours() + 12);
      cookies.set('authToken', reponse.access, {
        expires: date
      });
      await authService.updateToken(reponse.access)
      await getRules(reponse.access);
      navegate('/produtos')
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao fazer login',
        description: err?.response?.data?.message || err?.response?.data?.msg
      });
    }
  };

  const getRules = async (token?: string) => {
    try {
      const response = await authService.getPermissions({headers: {Authorization: `Bearer ${token}`}});
      const resPermissions = response?.user_groups_names ?? [];
      cookies.set('rules', JSON.stringify(resPermissions));
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao procurar permissões'
      });
    }
  };

  const onSignOut = () => {
    cookies.remove('authToken');
    navegate('/login');
  };

  const forceSignOut = () => {
    addPopup({
      type: 'info',
      title: 'Token expirado ou inválido'
    });
    onSignOut();
  };

  useEffect(() => {
    api.setForceSignOut(forceSignOut);
  }, []);

  return {
    onSignIn,
    onSignOut,
    forceSignOut
  };
};
