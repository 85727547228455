import { ReactNode, useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';

import { IPopupMessage, usePopup } from '../../../hooks/usePopup';
import { Animated, ButtonClose, SpanClose, TitleMessage } from './styles';
import { CheckCircleOutlineRounded, InfoOutlined } from '@mui/icons-material';

type IPopupProps = {
  message: IPopupMessage;
  style: object;
};

type IPopupData = {
  success: any;
  error: any;
  info: any;
};

const icons: IPopupData = {
  info: <InfoOutlined fontSize='medium' style={{ margin: '4px 12px 0 0' }} />,
  error: <InfoOutlined fontSize='medium' style={{ margin: '4px 12px 0 0' }} />,
  success: <CheckCircleOutlineRounded fontSize='medium' style={{ margin: '4px 12px 0 0' }} />
};

const variantColorsPopup: IPopupData = {
  info: {
    background: '#ebf8ff',
    color: '#3172b7'
  },
  success: {
    background: '#e6fffa',
    color: '#2e656a'
  },
  error: {
    background: '#fddede',
    color: '#c53030'
  }
};

const Description = ({ children }: { children: ReactNode }) => {
  if (children) {
    return typeof children === 'string' ? <p>{children}</p> : <>{children}</>;
  }

  return null;
};

export const Popup = ({ message, style }: IPopupProps) => {
  const { removePopup } = usePopup();
  const [color, setColor] = useState({
    background: '#ebf8ff',
    color: '#3172b7'
  });

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (message.autoClose) {
        removePopup(message.id);
      }
    }, 6000);

    const alignItem = !message.description
      ? {
          'alignItems': 'center',
          '> svg': {
            marginTop: 0
          }
        }
      : {};

    setColor({
      ...variantColorsPopup[message.type],
      ...alignItem
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Animated style={{ ...color, ...style }} data-cy='popup'>
      {icons[message.type || 'info']}

      <div style={{ flex: 1 }}>
        <TitleMessage>{message.title}</TitleMessage>
        <Description>{message.description}</Description>
      </div>

      <ButtonClose onClick={() => removePopup(message.id)} type='button'>
        <IoIosClose size='1.8rem' />
      </ButtonClose>
      {message?.autoClose && <SpanClose style={{ background: color.color }}></SpanClose>}
    </Animated>
  );
};
