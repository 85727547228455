import Health from 'components/Pages/Health/Health';
import Login from 'components/Pages/Login';
import ProductList from 'components/Pages/ProductList';
import { Routes as RoutesReactDom, Route } from 'react-router-dom';

export const Routes = () => {
  return (
    <RoutesReactDom>
      <Route path='/login' element={<Login />} />
      <Route path='/produtos' element={<ProductList />} />
      <Route path='/health' element={<Health />} />
      <Route path='*' element={<Login />} />
    </RoutesReactDom>
  );
};
