import {
  alpha,
  Box,
  Collapse,
  List,
  ListItem,
  styled
} from '@material-ui/core';
import { MdOutlineArrowDropUp } from 'react-icons/md';

export const FilterContainer = styled(List)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  height: '3.5rem',
  maxWidth: '350px',
  width: '50%',
  border: '1px solid #C4C4C4',
  borderRadius: '4px',
  paddingLeft: 0,
  '& .svg-icon-color': {
    color: '#757575'
  },
  '& .MuiList-root': {
    backgroundColor: theme.palette.background.paper,
    zIndex: '2'
  },
  '& .MuiCollapse-entered': {
    backgroundColor: theme.palette.background.paper
  },
  [theme.breakpoints.down(600)]: {
    width: '100%',
    maxWidth: 'none'
  },
  '& *': {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px',
      background: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: alpha(theme.palette.grey[600], 0.3),
      borderRadius: '9px'
    }
  }
}));

export const ButtonOpenFilter = styled(ListItem)(({ theme, isOpen }) => ({
  width: '100%',
  paddingTop: 0,
  paddingBottom: 0,
  height: '3.5rem',
  display: 'flex',
  gap: '1rem',
  justifyContent: 'space-between',
  '& > .MuiListItemIcon-root': {
    minWidth: 'auto'
  },
  '& .svg-icon-arrow': {
    transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out'
  }
}));

export const FilterOption = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 0,
  paddingTop: 0,
  cursor: 'pointer'
});

export const ContainerTextOption = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.9rem 0'
});

export const ArrowIconOption = styled(MdOutlineArrowDropUp)(({ isOpen }) => ({
  transition: 'all 0.3s ease-in-out',
  transform: isOpen ? 'rotate(90deg)' : 'rotate(180deg)',
  color: '#757575'
}));

export const ContainerButtonSubmit = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
});

export const ContainerListFilters = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingLeft: 0,
  [theme.breakpoints.up(1200)]: {
    position: 'absolute',
    left: '100%',
    width: '100%',
    maxWidth: '350px',
    top: 0
  },

  [theme.breakpoints.down(1200)]: {
    width: '100%'
  }
}));

export const ListFilters = styled(List)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '10px',
    background: 'transparent'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.grey[600], 0.3),
    borderRadius: '9px'
  }
}));

export const Filter = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.down(1200)]: {
    paddingLeft: 0
  }
}));
