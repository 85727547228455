import { styled, TableHead as MuiTableHead, TableRow } from '@material-ui/core';

const TableHead = styled(MuiTableHead)(({ theme }) => ({}));

export const TableRowHead = styled(TableRow)({
  '& th:first-of-type': {
    boxShadow: '#fafafa 10px 0px 0px inset',
    borderRadius: '0.5rem 0 0 0.5rem',
    paddingLeft: '1rem'
  },
  '& th:last-of-type': {
    boxShadow: '#fafafa -10px 0px 0px inset',
    borderRadius: '0 1rem 1rem 0'
  }
});

export default TableHead;
