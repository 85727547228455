import { keyframes, styled } from '@mui/material';
import { animated } from '@react-spring/web';

export const Animated = styled(animated.div)(({ theme }) => ({
  'width': '360px',
  'position': 'relative',
  'zIndex': 4000,
  'padding': '16px 30px 16px 16px',
  'boxShadow': '2px 2px 8px rgba(0,0,0,0.2)',
  'display': 'flex',
  '& + div': {
    marginTop: '6px'
  },
  [theme.breakpoints.down(600)]: {
    width: '100%'
  }
}));

export const TitleMessage = styled('strong')(({ theme }) => ({
  [theme.breakpoints.down(350)]: {
    fontSize: '12px'
  }
}));

export const ButtonClose = styled('button')({
  position: 'absolute',
  right: '2%',
  top: '10px',
  opacity: '0.6',
  border: '0',
  background: 'transparent',
  color: 'inherit',
  cursor: 'pointer'
});

const spanAnimation = keyframes`
  from {
    width:0%
  }
  to {
    width: 100%
  }
`;

export const SpanClose = styled('span')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '6px',
  animation: `${spanAnimation} 6s`
});
