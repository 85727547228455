import { makeStyles, darken } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: (props: any) => props.warning && theme.palette.warning.main,
    '&:hover': {
      background: (props: any) =>
        props.warning ? darken(theme.palette.warning.main, 0.1) : undefined
    }
  },
  fullScreen: {
    '@media(max-width: 400px)': {
      width: '100%'
    }
  },
  loading: {
    color: '#BF98FF',
    position: 'absolute'
  }
}));
