import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import cookies from 'js-cookie';

export class ApiService {
  public instance: AxiosInstance;
  private forceSignOut?: () => void;

  constructor() {
    this.instance = axios.create({
      headers: {
        Authorization: `Bearer ${cookies.get('authToken')}`
      }
    });

    this.instance.interceptors.response.use(
      response => response.data,
      error => {
        if (error?.response?.status === 401 && this.forceSignOut) {
          this.forceSignOut();
        }
        return Promise.reject(error);
      }
    );
  }

  public setForceSignOut = (param: () => void) => {
    this.forceSignOut = param;
  };

  public updateAuthToken = (token?: string) => {
    if (token) {
      return this.instance.defaults.headers.Authorization = `Bearer ${token}`;
    }
    return this.instance.defaults.headers.Authorization = `Bearer ${cookies.get('authToken')}`
  };

  public get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    this.updateAuthToken();
    return this.instance.get(url, config);
  };

  public post = async <T, P>(url: string, data?: P, config?: AxiosRequestConfig): Promise<T> => {
    return this.instance.post(url, data, config);
  };

  public put = async <T, P>(url: string, data?: P, config?: AxiosRequestConfig): Promise<T> => {
    return this.instance.put(url, data, config);
  };

  public delete = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    return this.instance.delete(url, config);
  };
}

export const api = new ApiService();
