import Form from './Form';
import { ContainerImage, ContainerPage, Image } from './styles';
import NaboIMG from 'assets/images/nabo-box.svg';

const Login = () => {
  return (
    <ContainerPage>
      <ContainerImage>
        <Image src={NaboIMG} alt='cesta de nabos' />
      </ContainerImage>
      <Form />
    </ContainerPage>
  );
};

export default Login;
