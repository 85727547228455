import { TableCell, TableSortLabel, styled, alpha } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';

const TableCellStyle = styled(TableCell)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.1),
  padding: '0.9rem 1rem',
  border: 'none'
}));

const TableSortLabelStyle = styled(TableSortLabel)({
  color: '#757575',
  padding: 0,
  '&.MuiTableSortLabel-active': {
    color: '#353535'
  },
  '&:hover': {
    background: 'transparent'
  }
});

const TableCellHeadComponent = props => {
  const {
    paginationParams,
    children,
    column,
    disabled,
    onChange,
    type,
    ...rest
  } = props;

  const isActive = useMemo(
    () => paginationParams?.orderBy === column,
    [column, paginationParams?.orderBy]
  );

  const { sortDirection, direction } = useMemo(() => {
    return {
      sortDirection: isActive ? paginationParams?.orderDirection : false,
      direction: paginationParams?.orderDirection === column ? 'asc' : 'desc'
    };
  }, [isActive, paginationParams?.orderDirection]);

  const handleChange = useCallback(() => {
    if (isActive && paginationParams.orderDirection !== column) {
      onChange({ orderBy: undefined, orderDirection: null });
      return;
    }

    const direction =
      isActive && paginationParams?.orderDirection === column
        ? `-${column}`
        : column;
    onChange({ orderBy: column, orderDirection: direction });
  }, [column, isActive, onChange, paginationParams?.orderDirection]);

  if (type === 'checkbox') {
    return (
      <TableCellStyle style={{ paddingLeft: '1.5rem' }} {...rest}>
        {children}
      </TableCellStyle>
    );
  }

  return (
    <TableCellStyle {...rest} sortDirection={sortDirection}>
      <TableSortLabelStyle
        disabled={disabled}
        active={isActive}
        direction={direction}
        onClick={handleChange}
      >
        {children}
      </TableSortLabelStyle>
    </TableCellStyle>
  );
};

const TableCellHead = memo(TableCellHeadComponent);

export default TableCellHead;
