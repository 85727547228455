import { Box, Container, IconButton, Typography } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

const Dialog = (props: { handleClose: any; open: any; title: any; children?: any; className?: any; }) => {
  const { handleClose, open, title, children, className } = props;
  const classes = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      className={`${classes.root} ${className}`}
    >
      <Container className={classes.container}>
        <Box className={classes.containerTitle}>
          <Typography className={classes.title}>{title}</Typography>

          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Container>
    </MuiDialog>
  );
};

export default Dialog;
