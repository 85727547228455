import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  styled,
  TableBody,
  TablePagination,
  TextField
} from '@material-ui/core';
import { Title } from 'components/Title';
import { useCallback, useState, useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import Scrollbar from 'shared/Scrollbar';
import { v4 } from 'uuid';

import FilterMenu from './FilterMenu/index';
import NoData from './NoData';
import TableCustom from './Table';
import TableCellBody from './TableCellBody';
import TableCellHead from './TableCellHead';
import tableConfig from './TableConfig';
import TableContainer from './TableContainer';
import TableHead, { TableRowHead } from './TableHead';
import TableRow from './TableRow';

const formatTextRowsPerPage = ({ to, from, count }: any) => {
  return `${from} - ${to} de ${count}`;
};

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  maxWidth: 'initial',
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    maxWidth: '350px'
  }
}));

const FormFilterStyle = styled(FormControl)(({ theme }) => ({
  maxWidth: 'initial',
  [theme.breakpoints.up('sm')]: {
    width: '45%',
    maxWidth: '300px'
  }
}));

const Table = (props: any) => {
  const {
    columns,
    checkboxSelection,
    onSelect,
    data,
    onChange,
    params,
    status,
    refresh,
    title,
    count,
    handleSearch,
    rowStyle,
    hover,
    filterProps,
    rowOnClick,
    size,
    newFilterProps,
    paginationServer = true
  } = props;
  const [selected, setSelected] = useState([]);
  const [paramsLocal, setParamsLocal] = useState({
    pageSize: tableConfig.rowsPerPageOptions[0],
    page: 1
  });
  const { isAllSelect, indeterminate } = useMemo(() => {
    return {
      isAllSelect: data.length > 0 && selected.length === data.length,
      indeterminate: selected.length > 0 && selected.length < data.length
    };
  }, [data, selected]);

  const isNoData = data.length === 0;

  const isSelected = (id: any) => !!selected.find(item => item.id === id);

  const handleSelectAllClick = (event: { target: { checked: any; }; }) => {
    if (event.target.checked) {
      setSelected(data);
      onSelect(data);
      return;
    }
    onSelect([]);
    setSelected([]);
  };

  const handleClick = useCallback(
    (    row: { id: any; }) => {
      setSelected(state => {
        const selectedItem = state.find(item => item.id === row.id);

        if (selectedItem) {
          const filterItems = state.filter(item => item.id !== row.id);
          onSelect(filterItems);
          return filterItems;
        }

        const items = [...state, row];
        onSelect(items);
        return items;
      });
    },
    [data, paramsLocal, params]
  );

  const { orderDirection, orderBy } = params;

  const { rows, pageSize, page } = useMemo(() => {
    if (paginationServer) {
      return {
        rows: data,
        pageSize: params.pageSize,
        page: params.page
      };
    }

    const { page, pageSize } = paramsLocal;

    return {
      rows: data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize),
      pageSize: paramsLocal.pageSize,
      page: paramsLocal.page
    };
  }, [params, paramsLocal, data]);

  return (
    <Paper style={{ margin: '5rem 0 1rem', padding: '1rem 0' }}>
      {title && (
        <Title style={{ padding: '0 1.5rem 0', fontWeight: 'bold' }}>
          {title}
        </Title>
      )}

      <Box
        style={{
          padding: '0.8rem',
          width: '100%',
          gap: '1rem',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {handleSearch && (
          <TextFieldStyle
            onChange={(...rest) => {
              if (!paginationServer) {
                setParamsLocal(state => ({ ...state, page: 1 }));
              }
              handleSearch(...rest);
            }}
            fullWidth
            variant="outlined"
            label="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              )
            }}
          />
        )}

        {filterProps && (
          <FormFilterStyle variant="outlined" fullWidth>
            <InputLabel shrink>{filterProps.label}</InputLabel>
            <Select
              label={filterProps.label}
              value={params[filterProps.parameter] || ''}
              defaultValue=""
              displayEmpty
              onChange={event =>
                onChange({
                  [filterProps.parameter]: event.target.value || undefined,
                  page: 1
                })
              }
            >
              <MenuItem value="">Todos</MenuItem>

              {filterProps.values.map((value: any) => {
                return (
                  <MenuItem key={value.id} value={value.id}>
                    {value.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormFilterStyle>
        )}

        {newFilterProps && (
          <FilterMenu filterProps={newFilterProps} onChangeParams={onChange} />
        )}
      </Box>

      <TableContainer style={{ minHeight: isNoData ? '21rem' : 'initial' }}>
        <TableCustom size={size || 'medium'}>
          <TableHead>
            <TableRowHead>
              {checkboxSelection && (
                <TableCellHead padding="checkbox" type="checkbox">
                  <Checkbox
                    indeterminate={indeterminate}
                    checked={isAllSelect}
                    onChange={handleSelectAllClick}
                  />
                </TableCellHead>
              )}

              {columns.map((column: any) => {
                return (
                  <TableCellHead
                    key={column?.field}
                    style={column?.style}
                    column={column?.orderBy || column.field}
                    align={column?.align || 'left'}
                    onChange={onChange}
                    disabled={column?.disabledSort}
                    paginationParams={{ orderDirection, orderBy }}
                    {...column}
                  >
                    {column.label}
                  </TableCellHead>
                );
              })}
            </TableRowHead>
            <TableRowHead>
              <TableCellBody
                colSpan={columns.length}
                style={{ padding: '0.4rem 0.7rem' }}
              >
                {status === 'pending' && (
                  <LinearProgress
                    color="secondary"
                    style={{ marginTop: '-10px' }}
                  />
                )}
              </TableCellBody>
            </TableRowHead>
          </TableHead>
          <TableBody>
            {rows.map((item: any) => {
              const isItemSelected = isSelected(item.id);
              const style = rowStyle ? rowStyle(item) : {};
              const id = v4();

              return (
                <TableRow
                  key={id}
                  selected={isItemSelected}
                  onClick={(e: any) => {
                    if (rowOnClick) {
                      rowOnClick(item);
                    }
                  }}
                  hover={!!checkboxSelection || hover}
                  style={{
                    cursor: rowOnClick ? 'pointer' : 'initial',
                    ...style
                  }}
                >
                  {checkboxSelection && (
                    <TableCellBody
                      padding="checkbox"
                      style={{ paddingLeft: '1.5rem' }}
                    >
                      <Checkbox
                        onClick={() => handleClick(item)}
                        checked={isItemSelected}
                      />
                    </TableCellBody>
                  )}
                  {columns.map((column: any) => {
                    const idCell = v4();
                    const { cellStyle } = column;
                    const style = cellStyle ? cellStyle(item) : {};

                    if (column?.rendercell) {
                      return (
                        <TableCellBody
                          style={style}
                          key={idCell}
                          align={column?.align || 'left'}
                        >
                          {column.rendercell({ data: item })}
                        </TableCellBody>
                      );
                    }

                    return (
                      <TableCellBody
                        key={idCell}
                        style={style}
                        align={column?.align || 'left'}
                      >
                        {column?.formatter
                          ? column.formatter(item[column.field])
                          : item[column.field]}
                      </TableCellBody>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableCustom>
        {status !== 'pending' && isNoData && (
          <NoData status={status} refresh={refresh} />
        )}
      </TableContainer>
      <Scrollbar style={undefined}>
        <TablePagination
          component="div"
          rowsPerPageOptions={tableConfig.rowsPerPageOptions}
          rowsPerPage={pageSize}
          onRowsPerPageChange={e => {
            if (paginationServer) {
              onChange({ pageSize: e.target.value, page: 1 });
              return;
            }
            setParamsLocal({ pageSize: Number(e.target.value), page: 1 });
          }}
          onPageChange={(_, newPage) => {
            if (paginationServer) {
              onChange({ page: newPage + 1 });
              return;
            }
            setParamsLocal(state => ({ ...state, page: newPage + 1 }));
          }}
          page={page - 1}
          count={count}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={formatTextRowsPerPage}
          align="right"
        />
      </Scrollbar>
    </Paper>
  );
};

export default Table;
