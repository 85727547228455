import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import { PermissionGate } from 'components/PermissionGate';
import { useRef, useState } from 'react';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import MenuPopover from 'shared/MenuPopover';

export const OptionsMenu = ({ data, options }) => {
  const [popover, setPopover] = useState(false);
  const optionsRef = useRef(null);

  const togglePopover = () => setPopover(state => !state);

  return (
    <>
      <IconButton
        ref={optionsRef}
        onClick={e => {
          e.stopPropagation();
          togglePopover();
        }}
      >
        <MdOutlineMoreHoriz color="#414141" size={22} />
      </IconButton>
      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={optionsRef?.current}
      >
        {options.map(option => {
          const { icon: Icon, action, title, permissions } = option;

          return (
            <PermissionGate key={title} permissions={permissions}>
              <MenuItem
                button
                style={{ color: option?.color }}
                onClick={() => action(data)}
              >
                <ListItemIcon style={{ minWidth: 40 }}>
                  <Icon size={20} style={{ color: option?.color }} />
                </ListItemIcon>
                <ListItemText primary={title} />
              </MenuItem>
            </PermissionGate>
          );
        })}
      </MenuPopover>
    </>
  );
};
