import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Dialog from 'shared/Dialog';

import { Form } from './Form';
import { Scanner } from './Scanner';

export const ModalPrint = ({ isOpen, close, dataProduct }) => {
  const [productBase, setProductBase] = useState({});
  const [batchExternalCode, setBatchExternalCode] = useState('');
  const [popUpPage, setPopUpPage] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-undef
  const batchId = new URLSearchParams(location.search).get('productBaseId');

  const handleClose = () => {
    close({ isOpen: false, data: {} });
    setPopUpPage(0);
    setBatchExternalCode('');
    setProductBase({});

    // eslint-disable-next-line no-undef
    const removeParams = window.location.pathname;
    navigate(removeParams);
  };

  const updateProductBase = () => {
    setProductBase(dataProduct);
    setPopUpPage(1);
  };

  const children = {
    0: (
      <Scanner
        setProductBase={setProductBase}
        dataProduct={productBase}
        onNext={() => setPopUpPage(state => ++state)}
      />
    ),
    1: (
      <Form
        dataProduct={productBase}
        handleClose={handleClose}
        onBack={() => setPopUpPage(state => --state)}
        batchExternalCode={batchExternalCode}
        setBatchExternalCode={setBatchExternalCode}
      />
    )
  }[popUpPage];

  useEffect(() => {
    !!Object.entries(dataProduct ?? {}).length && updateProductBase();
  }, [dataProduct]);

  useEffect(() => {
    if (isOpen) {
      navigate(`/produtos?productBaseId=${productBase?.id}`);
    }
  }, [productBase]);

  useEffect(() => {
    if (!batchId) {
      handleClose();
    }
  }, [batchId]);

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Imprimir etiqueta"
      fullScreen={isMobile}
    >
      {children}
    </Dialog>
  );
};
