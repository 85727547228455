import Pages from 'components/Pages';
import { PopupProvider } from 'hooks/usePopup';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <PopupProvider>
        <Pages />
      </PopupProvider>
    </BrowserRouter>
  );
}

export default App;
