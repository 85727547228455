const Health = () => {
  const data = Date();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          fontSize: '40px',
          color: 'green'
        }}
      >
        <p>Frexco</p>
        <p
          style={{
            fontSize: '20px',
            color: 'black'
          }}
        >
          {data}
        </p>
      </div>
    </>
  );
};

export default Health;
