import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const ContainerPage = styled('main')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  backgroundColor: '#FCFCFC'
});

export const ContainerImage = styled('section')({
  width: '50%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#B9CA94'
});

export const Image = styled('img')({
  width: '50%',
  maxWidth: '600px'
});

export const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  appBar: {
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: 'rgb(185, 202, 148)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  titleNav: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#FFF',
    fontWeight: 600,
  },
}));
