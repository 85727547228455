import { useCallback, useEffect, useState } from 'react';
import { useDebaunce } from 'utils/debaunce';

export const useTableParams = ({ service, paramsDefault }: any) => {
  const [status, setStatus] = useState('pending');
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: undefined,
    ...paramsDefault
  });
  const [count, setCount] = useState(0);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 400 });

  function handleSearch(e: { target: { value: any; }; }) {
    setParams((state: any) => ({ ...state, page: 1, search: e.target.value }));
  }

  const onChange = (newParams: any) => {
    setParams((state: any) => ({ ...state, ...newParams }));
  };
  const debaunceChange = useDebaunce({ fn: onChange, delay: 300 });
  const run = useCallback(async () => {
    try {
      setStatus('pending');
      const data = await service({
        ...params,
        pageIndex: params.page,
        orderBy: params.orderDirection
      });
      setData(data.results);
      setCount(data?.total_count || data?.total);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  }, [service, params]);

  useEffect(() => {
    run();
  }, [params]);

  return {
    data,
    setData,
    params,
    onChange: debaunceChange,
    status,
    count,
    refresh: run,
    handleSearch: debaunceSearch
  };
};
