import { ESTOQUE_BACKEND_URL } from '../settings';
import { api } from './api';

class BatchService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getBatches({
    page,
    status,
    stage,
    excludeStatus,
    receiptDateFrom,
    receiptDateTo,
    search,
    receiptDate,
    id,
    pageSize,
    purchaseItemReasonId,
    signal,
    wasteToClassify
  }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch`;
    return api.get(url, {
      params: {
        status,
        stage,
        excludeStatus,
        receiptDateFrom,
        receiptDateTo,
        search,
        receiptDate,
        page,
        id,
        pageSize,
        purchaseItemReasonId,
        wasteToClassify
      },
      signal
    });
  }

  async getSellerBatches({
    page,
    status,
    stage,
    excludeStatus,
    receiptDateFrom,
    receiptDateTo,
    search,
    id,
    pageSize,
    purchaseItemReasonId,
    signal
  }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/seller`;
    return api.get(url, {
      params: {
        status,
        stage,
        excludeStatus,
        receiptDateFrom,
        receiptDateTo,
        search,
        page,
        id,
        pageSize,
        purchaseItemReasonId
      },
      signal
    });
  }

  async getBatchDetail(batchId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/${batchId}`;
    return api.get(url);
  }

  async registerBatch(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/${id}`;
    return api.patch(url, data);
  }

  async createBatchReturn(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/return`;

    return api.post(url, data);
  }

  async patchBatchReturn({ id, data }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/return/${id}`;

    return api.patch(url, data);
  }

  async deleteBatchReturn(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/return/${id}`;

    return api.delete(url);
  }

  async newShipment(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/shipment`;
    return api.post(url, data);
  }

  async getBatchesReturn({
    page,
    statusId,
    stageId,
    excludeStatus,
    receiptDateFrom,
    receiptDateTo,
    search,
    id,
    pageSize,
    purchaseItemReasonId,
    alignmentDateFrom,
    alignmentDateTo,
    signal
  }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/return`;
    return api.get(url, {
      params: {
        statusId,
        stageId,
        excludeStatus,
        receiptDateFrom,
        receiptDateTo,
        search,
        page,
        id,
        pageSize,
        purchaseItemReasonId,
        alignmentDateFrom,
        alignmentDateTo
      },
      signal
    });
  }

  async getBatchesV2({
    page,
    status,
    stage,
    refusalInstructionsId,
    excludeStatus,
    receiptDateFrom,
    receiptDateTo,
    receiptDate,
    search,
    id,
    pageSize,
    purchaseItemReasonId,
    isSelection,
    hasAvailableQuantity,
    orderBy,
    signal
  }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2`;
    return api.get(url, {
      params: {
        status,
        stage,
        refusalInstructionsId,
        excludeStatus,
        receiptDateFrom,
        receiptDateTo,
        receiptDate,
        search,
        page,
        id,
        pageSize,
        purchaseItemReasonId,
        orderBy,
        isSelection,
        hasAvailableQuantity
      },
      signal
    });
  }

  async receiptBatchV2(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/${id}/receipt`;
    return api.patch(url, data);
  }

  async confirmEntryV2(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/${id}/confirm`;
    return api.post(url, data);
  }

  async selectionV2(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/${id}/selection`;
    return api.patch(url, data);
  }

  async getSubBatches({
    page,
    stage,
    search,
    pageSize,
    classification,
    receiptDate,
    signal
  }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/sub-batch`;
    return api.get(url, {
      params: {
        stage,
        search,
        page,
        pageSize,
        classification,
        receiptDate
      },
      signal
    });
  }

  async getSubBatchDetail(subBatchId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/sub-batch/${subBatchId}`;
    return api.get(url);
  }

  async moveSubbatch(subBatchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/sub-batch/${subBatchId}/move`;
    return api.patch(url, data);
  }

  async confirmBatch(batchId, { action, observation }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v2/${batchId}/blitz/step`;
    return api.post(url, { action, observation });
  }

  async getBatchReason(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/reason`;
    return api.get(url, { params });
  }
}

const batchService = new BatchService();
export default batchService;
