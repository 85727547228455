import { useEffect, useRef } from 'react';
import { QrReader } from 'react-qr-reader';

export const QRCodeScanner = ({ onScanSuccess, ...rest }) => {
  const scannerRef = useRef(null);

  const handleScan = result => {
    if (!result) return;
    onScanSuccess(result?.text);
  };

  useEffect(() => {
    return () => {
      if (scannerRef.current) {
        scannerRef.current.stop();
      }
    };
  }, []);

  return (
    <QrReader
      ref={scannerRef}
      delay={300}
      onResult={handleScan}
      constraints={{
        facingMode: 'environment'
      }}
      {...rest}
    />
  );
};
