import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  'position': 'fixed',
  'right': '0',
  'top': '1',
  'zIndex': 4,
  '& > div:first-child': {
    marginTop: '30px'
  },
  '& > div': {
    marginRight: '30px'
  },
  [theme.breakpoints.down(600)]: {
    // eslint-disable-line
    width: '100%',
    padding: '2%'
  }
}));
