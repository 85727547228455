/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import { makeStyles } from '@material-ui/core';
const { styled } = require('@material-ui/core');
const { QRCodeScanner } = require('components/QRCode');

export const QRCode = styled(QRCodeScanner)({
  height: '20rem',
  '& > div': {
    paddingTop: '0 !important',
    height: '100%',
    marginTop: '2rem',
  }
});

export const useStyles = makeStyles(theme => ({
  skipQRCodeBtn: {
    width: '7rem',
    [theme.breakpoints.between(0, 600)]: {
      width: '100%'
    }
  }
}));

