import { Popover, styled } from '@material-ui/core';

const ArrowStyle = styled('span')({
  top: '-12px',
  zIndex: 1,
  width: 12,
  right: 20,
  transform: 'translate(50%)',
  height: 12,
  content: "''",
  position: 'absolute',
  borderColor: 'transparent transparent #ffffff transparent',
  borderWidth: '5px',
  borderStyle: 'solid'
});

const PopOverStyle = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-elevation8': {
    boxShadow: theme.shadows[2],
    borderRadius: '0.5rem'
  },
  '& .MuiPopover-paper': {
    overflowY: 'initial',
    overflowX: 'initial',
    padding: '0.3rem'
  }
}));

const MenuPopover = ({ children, ...rest }: any) => {
  return (
    <PopOverStyle
      open={false} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...rest}    >
      <ArrowStyle />
      {children}
    </PopOverStyle>
  );
};

export default MenuPopover;
