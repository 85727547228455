import { AxiosRequestConfig } from 'axios';
import { ESTOQUE_BACKEND_URL, ABACATE_BACKEND_URL } from '../settings';
import { api } from './api';

class SkuService {
  url: string;
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async getSku(params: { sku?: any; productBaseId?: any; status?: string; }) {
    const url = `${ESTOQUE_BACKEND_URL}/api/products/v2/product`;
    return api.get(url, { params });
  }

  async postSku(form: any) {
    const url = `${this.url}/api/products/v2/product`;
    return api.post(url, form);
  }

  async updateSku(form: { id: any; }) {
    const url = `${this.url}/api/products/v2/product/${form.id}`;
    return api.put(url, form);
  }

  async deleteSku(id: any) {
    const url = `${this.url}/api/products/v2/product/${id}`;
    return api.delete(url);
  }

  async updateSkuImage(params: { idSku: any; file: any; }) {
    const url = `${this.url}/api/abacate/v1/public/product/${params.idSku}/image`;
    await api.put(url, { file: params.file });
  }

  async downloadSku(form: AxiosRequestConfig<any>) {
    const url = `${this.url}/api/abacate/v1/public/product/`;
    return api.delete(url, form);
  }

  async getDownloadSku() {
    const url = `${ESTOQUE_BACKEND_URL}/api/blueberry/v1/warehouse/storage/sku`;
    return api.get(url, {
      responseType: 'blob',
      params: {
        export: 'xls'
      }
    });
  }
}

const skuService = new SkuService();
export default skuService;
